import Model from "../Model";

class ComercialVisits extends Model{

    buildUrl ({ param }) {
        return ['visitsbyzone/getbyuserid', param];
    }

    all(config = {}) {
        return this.request({ method: 'POST', action: 'all', ...config });
    }
}


export default new ComercialVisits;